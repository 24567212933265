$background:#f3f6fd;
$color:#171a29;

$dark-theme:#14152d;

/*Color variables*/
$primary:#425bd6; 
$secondary:#02c3ee;
$pink:#f43f86;
$teal:#008080;
$cyan:#08bfe0;
$purple:#7400ad;
$success:#04b372;
$warning:#ffae00;
$danger:#f92136;
$info:#1aa8c7;
$orange:#f75b00;
$dark:#606061;
$indigo:#461f6e;
$yellow:#ffe15b;
$gray:#3c4858;
$white:#fff;
$black:#000;

/*border variables*/
$border:#ebf1ff;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-02:rgba(255, 255, 255, 0.02);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);


	

